import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./login.css";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Message from "../LeftBanner";
import GoogleIcon from "../../assets/google.png";
import Showpassword from "../../assets/showPass.png";
import HidePassword from "../../assets/openEye.png";
import { useUserAuth } from "../../context/UserAuthContext";
import useAuth from "../../hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OpguruLogo from "../../assets/opgurulogo.png";
import { useLoader } from "../../context/LoaderContext.js";
import UseAnalyticsTrack from "../../hooks/useAnalytics.js";

import axios from "../../api/axios";
const LOGIN_URL = "/api/auth";

const Login = () => {
  const { setIsLoading } = useLoader();
  const { googleSignIn } = useUserAuth();
  const { setAuth } = useAuth();

  const navigate = useNavigate();

  const [phno, setPhno] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState("Enter a 10 digit phone number");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userdata = {
      phno: phno,
      password: password,
    };
    setError("");
    if (password && phno) {
      console.log("Successfully entered user details");
      try {
        setIsLoading(true);
        const response = await axios.post(LOGIN_URL, userdata, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
        console.log("response data in login is ", response.data);
        const newAuth = {
          id: response.data.id,
          phno: phno,
          name: response.data.name,
          userID: response.data.userID,
          accessToken: response.data.accessToken,
          role: response.data.role
        };
        setAuth(newAuth);
        const nextroute = response?.data.loginroute;
        const gaEventTrack = UseAnalyticsTrack("Login");
        gaEventTrack("Login Succesfull" , "Login Attempt");
        navigate(`/${nextroute}`);
      } catch (err) {
        console.log("Error is ", err);
        const errorMessage = err.response?.data ? err.response.data : null;
        // The above thing is very important for error handling, because if err.response.data likha direct and
        // err.response hi nhi exist krta toh code vhi ruk jayega, write err.response?.data, so that if something
        // doesnt exist, directly put it null
        if (errorMessage === null) {
          toast.error(
            "An unknown error occured at the server! Please try again later",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          toast.error(errorMessage, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      toast("Please enter valid user details");
    }
  };

  const changeVisiblity = () => {
    setVisible(!visible);
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate("/usertype");
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <section className="loginWrapper">
      <div className="messageLogin">
        <Message></Message>
      </div>
      <div className="userLoginForm">
        <div className="midWrapperLogin">
          <div className="authMessage">
            <div className="authMessageText">Login to</div>
            <div
              className="opguruLogoAuth"
              onClick={() => {
                navigate("/home");
              }}
            >
              <img src={OpguruLogo} alt="opguruLogo" />
            </div>
          </div>
          <div className="welcomeMessage">
            <span className="welcomeMessageSpan">Welcome back !</span> Please
            enter your details.
          </div>
          <form onSubmit={handleSubmit} id="loginForm">
            <div className="phonenumWrapLogin">
              <PhoneInput
                defaultCountry="IN"
                value={phno}
                onChange={setPhno}
                placeholder="Enter Phone Number"
                required
              />
            </div>
            <div className="passwordWrap">
              <input
                type={visible ? "text" : "password"}
                id="password"
                placeholder="Password"
                autoComplete="off"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                className="inputPassword"
              />
              <div className="showPassword">
                {visible ? (
                  <Button className="showPasswordBtn" onClick={changeVisiblity}>
                    <img className="eyeImg" src={HidePassword} alt="/" />
                  </Button>
                ) : (
                  <Button className="showPasswordBtn" onClick={changeVisiblity}>
                    <img className="eyeImg" src={Showpassword} alt="/" />
                  </Button>
                )}
              </div>
            </div>
            <div className="loginOptions">
              <div className="rememberLogin">
                <input
                  type="checkbox"
                  className="rememberCheckBox"
                  style={{ width: "7%", paddingRight: "0.2rem" }}
                />
                Remember for 30 days
              </div>
              <div className="forgotPassword">
                <Link to="/forgotpassword/phno" className="linkStyle1">
                  Forgot Password
                </Link>
              </div>
            </div>
          </form>
          <button
            type="submit"
            className="yellowBtn"
            form="loginForm"
            value="Submit"
          >
            Login
          </button>
          {/* <div className="userIDLogin">
            <Button className="whiteBtn">Login Via Email or User ID</Button>
          </div>
          <div className="methodChoice">Or</div>
          <Button className="whiteBtn" onClick={handleGoogleSignIn}>
            <div className="googleSignUp">
              <div className="googleIcon">
                <img src={GoogleIcon} alt="/" className="GoogleImg" />
              </div>
              <div className="googleMessage">Sign in with Google</div>
            </div>
          </Button> */}
          <div className="notRegistered">
            <div className="notRegisteredMessage">
              <div className="notRegisteredText">Don’t have an account?</div>
              <div
                className="notRegisteredTextSpan"
                onClick={() => {
                  navigate("/register");
                }}
              >
                Sign up for free
              </div>
            </div>
            <div className="alreadyLogin">
              Go back to
              <Link to="/" className="linkStyle1">
                {" "}
                Home
              </Link>
            </div>
          </div>

          <ToastContainer />
        </div>
      </div>
    </section>
  );
};

export default Login;
