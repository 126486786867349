import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SidebarFields from "./SidebarFields.js";
import "./index.css";
import useLogout from "../../../hooks/useLogout";

const Sidebar = () => {
  const logout = useLogout();
  const navigate = useNavigate();
  const location = useLocation();

  const signOut = async () => {
    await logout();
    navigate("/");
  };

  const checkActive = (linkTo) => {
    // Remove leading slash from current path for comparison
    const currentPath = location.pathname.replace(/^\//, '');
    return currentPath === linkTo;
  };

  const handleNavigation = (path) => {
    // Add leading slash when navigating
    navigate(`/${path}`);
  };

  return (
    <aside className="sidebarWrapper">
      <div className="sidebarContent">
        <nav className="sidebarRoutes">
          {SidebarFields.map((item, index) => {
            const isActive = checkActive(item.linkTo);
            return (
              <div
                key={index}
                className={`sidebarRoute ${isActive ? 'active' : ''}`}
                onClick={() => handleNavigation(item.linkTo)}
              >
                <div className="routeIcon">
                  <img src={item.Icon} alt="" />
                </div>
                <div className="routeName">{item.name}</div>
              </div>
            );
          })}
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;