import React, { useState, useEffect, useRef } from "react";
import axios from "../api/axios";
import "./Chatbot.css";

const Chatbot = ({ onClose }) => {
  const CHATBOT_ROUTE = "/api/chatbot";

  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("messages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [inputMessage, setInputMessage] = useState("");
  const messagesEndRef = useRef(null);

  useEffect(() => {
    localStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const formatBotResponse = (response) => {
    // If response is a string, return it directly
    if (typeof response === 'string') {
      return response;
    }
    
    // If response is an object, format it nicely
    if (typeof response === 'object' && response !== null) {
      // Filter out null or undefined values
      const validEntries = Object.entries(response).filter(([_, value]) => 
        value !== null && value !== undefined
      );
      
      // Format each key-value pair
      return validEntries.map(([key, value]) => {
        const formattedKey = key.replace(/_/g, ' ').toLowerCase();
        return `${formattedKey}: ${value}`;
      }).join('\n');
    }
    
    // Fallback for other cases
    return 'Sorry, I could not process that response.';
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;
    console.log("inputMessage is ", inputMessage);
    const newMessage = { text: inputMessage, sender: "user" };
    setMessages([...messages, newMessage]);
    setInputMessage("");

    try {
      const response = await axios.post(CHATBOT_ROUTE, {
        message: inputMessage,
      });
      const reply = formatBotResponse(response.data.message);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: reply, sender: "bot" },
      ]);
    } catch (error) {
      console.error("Error:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "Sorry, I encountered an error. Please try again.", sender: "bot" },
      ]);
    }
  };

  return (
    <div className="chatbot-modal" onClick={onClose}>
      <div className="chatbot-container" onClick={(e) => e.stopPropagation()}>
        <div className="chatbot-header">
          Hii ! Welcome to OpportuneBot
          <button className="close-btn" onClick={onClose}>
            ×
          </button>
        </div>
        <div className="chatbot-messages">
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.sender}`}>
              {typeof msg.text === 'string' ? (
                msg.text.split('\n').map((line, i) => (
                  <p key={i} className="mb-1">{line}</p>
                ))
              ) : (
                'Invalid message format'
              )}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className="chatbot-input-container">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
            placeholder="Ask me anything..."
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;