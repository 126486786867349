import React, { useEffect, useState } from "react";
import "./index.css";
import Header from "../Headers/HeaderProfile";
import UserProfile from "./UserProfile/index.js";
import AboutCard from "./Cards/AboutCard.jsx";
import AboutModal from "./AboutModal";
import PersonalModal from "./PersonalModal";
import PersonalCard from "./Cards/PersonalCard.jsx";
import SkillsCardModal from "./SkillsCardModal";
import SkillsCard from "./Cards/SkillsCard.jsx";
import SocialLinksCard from "./Cards/SocialLinksCard.jsx";
import EditLinksModal from "./SocialLinksModal";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Usables/Sidebar";
import { useLoader } from "../../context/LoaderContext.js";
import axios from "../../api/axios";
import Below8thProfile from "./PersonalProfile/Below8thProfile/index.jsx";
import NineTenthProfile from "./PersonalProfile//NineTenthProfile/index.jsx";
import ElevenTwelthProfile from "./PersonalProfile//ElevenTwelthProfile/index.jsx";
import BachelorsProfile from "./PersonalProfile//BachelorsProfile/index.jsx";
import MastersProfile from "./PersonalProfile//MastersProfile/index.jsx";
import PhdProfile from "./PersonalProfile//PhdProfile/index.jsx";

const Profile = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [AboutContent, setAboutContent] = useState("");
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isPersonalModalOpen, setIsPersonalModalOpen] = useState(false);
  const { setIsLoading } = useLoader();
  const [personalData, setPersonalData] = useState({});
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const getUserData = async () => {
      try {
        setIsLoading(true); // Start loading

        // Fetch user data
        const userDetail = await axios.get("/api/getuserdata", {
          params: {
            userID: auth?.userID,
          },
        });
        const userData = userDetail?.data;
        const userCategory = userDetail?.data.category;
        setCategory(userCategory);
        console.log("Category of user is ", category);
        // Fetch authentication data
        const authDetail = await axios.get("/api/getauthdata", {
          params: {
            userID: auth?.userID,
          },
        });
        const authData = authDetail?.data;

        // Construct the new data object
        const newData = {
          fullName: `${userData.firstname} ${userData.lastname}`,
          contactNumber: authData.phno,
          userID: authData.userID,
          email: authData.email,
          gender: userData.gender,
          address: `${userData.city}, ${userData.country}, ${userData.pin}`,
        };

        // Set the data in state
        setPersonalData(newData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Stop loading
      }
    };

    getUserData();
  }, []);

  const [isSkillsModalOpen, setIsSkillsModalOpen] = useState(false);
  const [skills, setSkills] = useState([]);
  const [isSocialModalOpen, setIsSocialModalOpen] = useState(false);

  const [editedLinks, setEditedLinks] = useState({
    linkedin: "",
    facebook: "",
    twitter: "",
    instagram: "",
  });

  const handleSocialClick = () => {
    setIsSocialModalOpen(true);
  };

  const handleSocialModalClose = () => {
    setIsSocialModalOpen(false);
  };

  const handleSaveSocialLinks = (newLinks) => {
    setEditedLinks(newLinks);
    setIsSocialModalOpen(false);
  };
  const handleSkillsClick = () => {
    setIsSkillsModalOpen(true);
  };

  const handleCloseSkillsModal = () => {
    setIsSkillsModalOpen(false);
  };

  const handleSaveSkills = (selectedSkills) => {
    setSkills(selectedSkills);
  };
  const handleEditClick = () => {
    setIsAboutModalOpen(true);
  };

  const handleCloseAboutModal = () => {
    setIsAboutModalOpen(false);
  };
  const handleSave = (newContent) => {
    setAboutContent(newContent);
    setIsAboutModalOpen(false);
  };
  const handleEditClickPersonal = () => {
    setIsPersonalModalOpen(true);
  };
  const handleClosePersonalModal = () => {
    setIsPersonalModalOpen(false);
  };
  const handleSavePersonalData = (newData) => {
    setPersonalData(newData);
  };
  const handleEdit = async () => {
    const userId = auth.userID;
    navigate("/userType");
  };
  return (
    <section className="profileWrapper">
      {/* Main navbar */}
      <Header />

      <div className="container">
        {/* SideNav */}
        <div className="side-navbar flex flex-column space-between sidebarProfile">
          <Sidebar></Sidebar>
        </div>

        {/* Main Content */}
        <div className="main-content">
          <UserProfile personalData={personalData}></UserProfile>
          {/* Personal details card */}
          <div>
            <PersonalCard
              personalData={personalData}
              onEditClick={handleEditClickPersonal}
            />
            {isPersonalModalOpen && (
              <PersonalModal
                isOpen={isPersonalModalOpen}
                onClose={handleClosePersonalModal}
                initialData={personalData}
                onSave={handleSavePersonalData}
              />
            )}
          </div>

          {/* About Card */}
          <div>
            <AboutCard content={AboutContent} onEditClick={handleEditClick} />
            {isAboutModalOpen && (
              <AboutModal
                isOpen={isAboutModalOpen}
                onClose={handleCloseAboutModal}
                onSave={handleSave}
                initialContent={AboutContent}
              />
            )}
          </div>
          {category === "below8th" ? (
            <Below8thProfile userID={auth.userID} category={category} />
          ) : category === "9-10th" ? (
            <NineTenthProfile userID={auth.userID} category={category} />
          ) : category === "11-12th" ? (
            <ElevenTwelthProfile userID={auth.userID} category={category} />
          ) : category === "bachelors" ? (
            <BachelorsProfile userID={auth.userID} category={category} />
          ) : category === "masters" ? (
            <MastersProfile userID={auth.userID} category={category} />
          ) : category === "phd" ? (
            <PhdProfile userID={auth.userID} category={category} />
          ) : (
            <h1>Unknown category</h1>
          )}
          {/* My Skills */}

          <SkillsCard skills={skills} onEditClick={handleSkillsClick} />
          {isSkillsModalOpen && (
            <SkillsCardModal
              isOpen={isSkillsModalOpen}
              onClose={handleCloseSkillsModal}
              initialSkills={skills}
              onSave={handleSaveSkills}
            />
          )}

          {/*Social links*/}

          <SocialLinksCard
            onEditClick={handleSocialClick}
            editedLinks={editedLinks}
          />
          {isSocialModalOpen && (
            <EditLinksModal
              isOpen={isSocialModalOpen}
              onClose={handleSocialModalClose}
              onSave={handleSaveSocialLinks}
              currentLinks={editedLinks}
            />
          )}
        </div>
        <div className="further-details">
          {/* <div className="card">
            <h1 className="card-subtitle" style={{ marginBottom: "3rem" }}>
              Profile Details
            </h1>
            <div className="flex card-details space-between">
              <p>Personal Detail</p>
              <p className="green">100%</p>
            </div>
            <hr className="light-divider" />

            <div className="flex card-details space-between">
              <p>About</p>
              <p className="yellow">73%</p>
            </div>
            <hr className="light-divider" />

            <div className="flex card-details space-between">
              <p>Education</p>
              <p className="red">34%</p>
            </div>
            
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Profile;
