import React, { useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";

const Landing = (props) => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if user is authenticated
    const checkAuth = () => {
      if (user && Object.keys(user).length > 0 && user.uid) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, [user]); // Add user as dependency

  const handleNavigation = () => {
    console.log("Current auth state:", isAuthenticated);
    console.log("Current user:", user);

    if (isAuthenticated) {
      navigate("/opportunities");
    } else {
      navigate("/register");
    }
  };

  return (
    <div className="landingWrapper">
      <div className="landingHeading1">{props.heading1}</div>
      <div className="landingHeading2">{props.heading2}</div>
      <div className="landingBtn">
        <button
          onClick={handleNavigation}
          className="yellowBtn homeBtn"
        >
          {props.buttonName}
        </button>
      </div>
    </div>
  );
};

export default Landing;