// AIMessage.js
import React, { useState } from "react";
import "./index.css";
import Robot from "../../../assets/Robot.png";
import Chatbot from "../../../ChatBot/ChatBot"; // Make sure this path matches your project structure

const AIMessage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="aiMsgWrapper">
        <div className="aiMsgHeading">AI OPPORTUNITIES</div>
        <div className="aiMsgDescription">
          AI generate a best opportunities for you only.
        </div>
        <div className="aiMsgImgCon">
          <img src={Robot} alt="/" />
        </div>
        <div className="aiMsgButton">
          <button className="whiteBtn" onClick={toggleModal}>
            Grab Now
          </button>
        </div>
      </div>
      {isModalOpen && <Chatbot onClose={closeModal} />}
    </>
  );
};

export default AIMessage;