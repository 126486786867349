import Bell from "../../../assets/Bell.png";
import User from "../../../assets/User.png";
import Support from "../../../assets/Support.png";
import Clients from "../../../assets/Clients.png";
import Dashboard from "../../../assets/Dashboard.png";

const SidebarFields = [
  {
    name: "Profile",
    Icon: User,
    linkTo: "profile",
  },
  // {
  //   name: "Dashboard",
  //   Icon: Dashboard,
  //   linkTo: "dashboard",
  // },
  {
    name: "Opportunities",
    Icon: Clients,
    linkTo: "opportunities",
  },
  // {
  //   name: "Support",
  //   Icon: Support,
  //   linkTo: "support",
  // },
];

export default SidebarFields;
