import React, { useState } from "react";
import ProfileImgHeader from "../../../assets/profileImage.png";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import useLogout from "../../../hooks/useLogout";
import MobileNav from "./mobile-nav";
import BellIcon from "../../../assets/Bell.png";
import OpguruLogo from "../../../assets/opgurulogowhite.png";
import "./index.css";

const Header = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate("/");
  };

  return (
    <div className="headerWrapperProfile">
      <div className="headerLeftProfile">
        <div className="hamburgerProfile">
          <MobileNav></MobileNav>
        </div>
        <div className="headerLogoProfile" onClick={() => navigate("/home")}>
          <img className="headerLogoProfileImg" src={OpguruLogo} alt="/" />
        </div>
      </div>
      <div className="userDetailsHeaderProfile">
        {/* <div className="iconContainer">
          <img src={BellIcon} alt="Notification Bell" className="icon" />
        </div> */}
        <div className="userName">
          {auth?.name ? auth?.name : "Not logged in"}
        </div>
        {/* <div className="iconContainer">
          <img src={ProfileImgHeader} alt="Profile" className="profileIcon" />
        </div> */}
        <div className="headerLogoutWrapper">
          <button className="yellowBtn" onClick={signOut}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
