import ReactGA from "react-ga4";

const UseAnalyticsTrack = (category = "test category") =>{
    const eventTracker = (action = "test action" , label = "test label") =>{
        ReactGA.event({
            category: category,
            action: action,
            label: label
        });
    }
    return eventTracker;
} 

export default UseAnalyticsTrack;