import React, { useState } from "react";
import MyButton from "../../Usables/Buttons/Button";
import "./index.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    enquiry: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          access_key: "b59b4fc1-f77e-4830-8c43-c834ff513ded", // Replace with your Web3Forms access key
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          message: formData.enquiry,
          subject: "New Contact Form Submission - OpGuru",
        }),
      });

      const result = await response.json();
      if (response.status === 200) {
        setSubmitStatus("success");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          enquiry: "",
        });
      } else {
        setSubmitStatus("error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmitStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="form-container-contact-us">
      <div className="form-title-contact-us">Contact Us</div>
      <div className="form-description">
        Learn more about how OpGuru can help you with your journey
      </div>
      
      {submitStatus === "success" && (
        <div className="success-message">
          Thank you for contacting us! We'll get back to you soon.
        </div>
      )}
      
      {submitStatus === "error" && (
        <div className="error-message">
          Something went wrong. Please try again later.
        </div>
      )}

      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label className="form-label">First Name</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="form-input"
            required
            disabled={isSubmitting}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="form-input"
            required
            disabled={isSubmitting}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Business Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-input"
            required
            disabled={isSubmitting}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Your Enquiry</label>
          <textarea
            name="enquiry"
            value={formData.enquiry}
            onChange={handleChange}
            className="form-textarea"
            disabled={isSubmitting}
          />
        </div>
        <button 
          type="submit" 
          className="form-submit-button"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </form>
      <p className="form-footer">
        By submitting your information, you agree to receive promotional emails
        from OpGuru. You can manage your email preferences or unsubscribe at any
        time.
      </p>
    </div>
  );
};

export default ContactForm;